import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import Link from 'next/link';

import Layout from 'components/partials/Layout';
import {
  login, verifyEmail, getUserData, handlePhoneVerifyFlow,
} from 'models/user';
import { getUserDeposit } from 'models/payment';
import socketInstance from 'utils/socket.io';
import { UserContext } from 'contexts/userContext';
import { emailOrPhoneValidator, passwordValidator } from 'utils/formValidators';
import { StatusContext } from 'contexts/statusContext';
import { withTranslation } from 'language/i18n';
import { TextInput, Form, Button } from 'components/common';
import { SET_ALL } from 'contexts/constants/userConstants';
import { SET_SOCKET_STATUS } from 'contexts/constants/statusConstants';
import { LOGIN_REGEX } from 'constants/regex';
import LoginFooter from './components/LoginFooter';

const Login = ({ t }) => {
  const { dispatch: userDispatch } = useContext(UserContext);
  const { dispatch: statusDispatch, setErrorState } = useContext(StatusContext);

  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [onResponse, setOnResponse] = useState(false);

  const router = useRouter();

  const onVerifyEmail = async () => {
    if (!onResponse) {
      try {
        setOnResponse(true);
        if (!LOGIN_REGEX.EMAIL.test(emailOrPhone)) {
          setErrorState({
            title: t('error'),
            text: 'Lütfen giriş yaparken telefon numarası yerine e-posta adresinizi kullanın.',
            buttonText: t('close'),
            onClickButton: () => setErrorState({}),
          });
        }
        const email = emailOrPhone;

        await verifyEmail(email);
        setErrorState({
          title: t('successful'),
          text: `Onay e-postası ${email} adresinize gönderilmiştir.`,
          buttonText: t('close'),
          type: 'success',
          onClickButton: () => setErrorState({}),
        });
      } catch (error) {
        console.log('onVerifyEmailErr', error);
        throw error;
      } finally {
        setOnResponse(false);
      }
    }
  };

  const errorHandler = (error) => {
    if (error.message) {
      if (error.errorCode === 1004) {
        setErrorState({
          title: t('alert'),
          text: error.message,
          buttonText: t('sendVerificationEmail'),
          onClickButton: () => onVerifyEmail(),
        });
      } else if (error.errorCode === 1005) {
        setErrorState({
          title: t('alert'),
          text: t('phoneNotVerified'),
          buttonText: t('verifyMyPhone'),
          onClickButton: async () => {
            setErrorState({});
            await handlePhoneVerifyFlow(error.data.token);
          },
        });
      } else {
        setErrorState({
          title: t('alert'),
          text: error.message,
          buttonText: t('close'),
          onClickButton: () => setErrorState({}),
        });
      }
    }
  };

  const onClickLogin = async () => {
    if (!onResponse) {
      try {
        setOnResponse(true);
        const phoneMatch = emailOrPhone.match(LOGIN_REGEX.PHONE);
        const input = phoneMatch ? `90${phoneMatch[2]}` : emailOrPhone;
        await login(input, password);
        const { user } = await getUserData();
        const { userBuyingLimit } = await getUserDeposit();
        userDispatch({
          type: SET_ALL,
          payload: {
            ...user, userBuyingLimit, isBuyer: user.isBuyer, isSeller: user.isSeller,
          },
        });
        socketInstance.connect(
          () => statusDispatch({
            type: SET_SOCKET_STATUS,
            payload: true,
          }),
          () => statusDispatch({
            type: SET_SOCKET_STATUS,
            payload: false,
          }),
        );
        router.push(router.query?.redirectTo || '/', undefined, { shallow: true });
      } catch (error) {
        console.log('loginErr', error);
        errorHandler(error);
      } finally {
        setOnResponse(false);
      }
    }
  };

  return (
    <main className="has-header">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-15 col-sm-11">
            <div className="im-login mt-5">
              <div className="row no-gutters align-items-stretch">
                <div className="col-15 col-md-6 col-lg-5">
                  <div className="im-login__cover">
                    <div className="im-login__cover__heading">
                      <div>İkinci el iş makinası</div>
                      <div className="fw-900">alıp satmanın en iyi yolu</div>
                    </div>
                  </div>
                </div>
                <div className="col-15 col-md-9 col-lg-10">
                  <Form layout="vertical" onFinish={onClickLogin}>
                    <div className="im-login__form">
                      <div className="row justify-content-center">
                        <div className="col-12">
                          <div className="row mb-3">
                            <div className="col-15">
                              <span className="fs-22 fw-700">Lütfen Giriş Yapın</span>
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col-15">
                              Satmak istediğiniz 2.el iş makinanınızın kaydını oluşturmak ya da açık artırmadaki makinalara teklif vermek için lütfen giriş yapınız.
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-15 mb-4">
                              <TextInput
                                name="emailOrPhone"
                                value={emailOrPhone}
                                placeholder={t('emailOrPhone')}
                                onChange={({ value }) => setEmailOrPhone(value)}
                                validator={emailOrPhoneValidator}
                                required
                              />
                            </div>
                            <div className="col-15">
                              <TextInput
                                type="password"
                                name="password"
                                value={password}
                                placeholder={t('password')}
                                onChange={({ value }) => setPassword(value)}
                                validator={passwordValidator}
                                required
                              />
                            </div>
                          </div>
                          <div className="row mb-5">
                            <div className="col-15 text-right">
                              <Link href="/forgot-password/email-enterence">
                                <a>{t('forgotPassword')}</a>
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-15 mb-3">
                              <Button
                                loading={onResponse}
                                htmlType="submit"
                                className="im-button im-button--primary im-button--fluid im-button--center"
                                label={t('login')}
                              />
                            </div>
                            <div className="col-15">
                              <Link href="/register/membership-type">
                                <a className="im-button im-button--outline-yellow2 im-button--fluid im-button--center">
                                  {t('register')}
                                </a>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            <LoginFooter />
          </div>
        </div>
      </div>
    </main>
  );
};

Login.propTypes = {
  t: PropTypes.func,
};

export const getServerSideProps = async ({ req }) => {
  const { t } = req;

  return {
    props: {
      shortHeader: true,
      hideFooter: true,
      headTitle: t('login'),
    },
  };
};

Login.Layout = Layout;

export default withTranslation('common')(Login);
